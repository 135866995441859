<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-21 17:19:39
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-22 10:19:25
-->
<template>
  <div class="audit-table">
    <a-modal class="tag-form" title="查看商品" style="top: 8px;" width="80%" v-model="visible" :footer="null" :maskClosable="false">
      <a-table :columns="columns" :data-source="dataList">
        <span slot="imgUrl" slot-scope="item">
          <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
        </span>
      </a-table>
      <div class="footer-bts">
        <a-button type="default" @click="handleCancel()">取消</a-button>
        <a-button type="primary" @click="onRefuse()">拒绝</a-button>
        <a-button type="primary" @click="toSubmit()">通过</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        dataList: [],
        columns: [
          {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '商品图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            /*width:150,*/
            align:'center',
            ellipsis:true,
            scopedSlots: { customRender: 'imgUrl'}
          },
          {
            title: '标签',
            dataIndex: 'purchasePrice',
            key: 'purchasePrice',
            /*width:150,*/
            align:'center',
            ellipsis:true
          },
          {
            title: '分类',
            dataIndex: 'parentCategoryName',
            key: 'parentCategoryName',
            /*width:150,*/
            align:'center',
            ellipsis:true
          },
          {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            /*width:150,*/
            align:'center',
            ellipsis:true
          },
          {
            title: '销量',
            key: 'tags',
            dataIndex: 'tags',
            scopedSlots: { customRender: 'tags' }
          },
          {
            title: '上架状态',
            dataIndex: 'flagUp',
            key: 'flagUp',
            /*width:150,*/
            align:'center',
            ellipsis:true,
            scopedSlots: { customRender: 'flagUp'}
          },
        ]
      }
    },
    methods: {
      isShow(item) {
        this.visible = true
        this.axios.get('/api/product/system/info/selectById/' + item.id).then(res => {
          this.dataList = res.body.goodsList
        })
      },
      toSubmit() {
        this.visible = false
      },
      // 拒绝
      onRefuse() {
        this.visible = false
      },
      // 禁用弹框
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>

<style lang="scss">
  .footer-bts {
    text-align: right;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #E9E9E9;
  }
</style>
